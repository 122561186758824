.footer-block {
    padding: 1vh;
}

footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #000;
    padding: 1vh;
    font-size: 80%;
    z-index: 1000;
}

.social-links {
    list-style: none;
    float: left;
}

.icon {
    padding: 1vh;
    width: 200px;
}

.social-links li a:link,
.social-links li a:visited {
    text-decoration: none;
    border: 0;
    color: #888;;
    transition: color 0.2s;
}


.ion-logo-facebook,
.ion-logo-linkedin {
    transition: color 0.2s;
}

.ion-logo-facebook:hover { color: #3b5998; }

.ion-logo-linkedin:hover { color: #0077B5; }

footer p {
    color: #888;
    font-size: 80%;
    float: right;
}