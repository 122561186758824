.mainNav {
    width: 100%;
    list-style: none;   
    padding-right: 1vh;
    position: fixed;
    overflow: hidden;
    text-align: right;
    z-index: 1000;
}

.mainNavItem {
    display: inline-block;  
}

.listItem {
    margin: 2vh;
    text-decoration: none;
    font-family:'Dancing Script','Lato', 'Arial', 'sans-serif';
    font-weight: 500;
    font-size: 110%;
    border-bottom: 2px solid transparent;
    color: #F7F4F4;
}

.tabLink:hover span,
.tabLink:active span {   
    color: #000;
    border-bottom: 2px solid rgb(0, 0, 0);   
}

.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #313131;
    box-shadow: 0 2px 2px #1c1c1c;
    z-index: 100;
}

.drawerItem {
    text-decoration: none;
    font-family:'Dancing Script','Lato', 'Arial', 'sans-serif';
    font-weight: 500;
    font-size: 110%;
    color: #ccc1c1;

    &:hover,
    &:active {
        color: #F7F4F4;
        border-bottom: 2px solid rgb(247, 244, 244); 
    }
}

